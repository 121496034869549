import { useEffect, useState } from 'react';

function useStylesheet(src, options) {
  const { insertFirst = false, skip = false } = options;
  // Keep track of stylesheet status ("loading", "ready", "error")
  const [status, setStatus] = useState(skip ? 'skipped' : 'loading');

  useEffect(() => {
    if (!skip) {
      let stylesheet = document.querySelector(
        `link[href="${src}"][rel="stylesheet"]`
      );
      if (!stylesheet) {
        stylesheet = document.createElement('link');
        stylesheet.type = 'text/css';
        stylesheet.rel = 'stylesheet';
        stylesheet.href = src;
        stylesheet.media = 'only x';

        stylesheet.setAttribute('data-status', 'loading');
        if (insertFirst) {
          document.head.insertBefore(stylesheet, document.head.firstChild);
        } else {
          document.head.appendChild(stylesheet);
        }

        const setAttributeFromEvent = (event) => {
          const status = event.type === 'load' ? 'ready' : 'error';
          if (status === 'load') {
            stylesheet.media = 'all';
          }
          stylesheet.setAttribute('media', 'all');
          stylesheet.setAttribute('data-status', status);
        };

        stylesheet.addEventListener('load', setAttributeFromEvent);
        stylesheet.addEventListener('error', setAttributeFromEvent);
      } else {
        const stylesheetStatus = stylesheet.getAttribute('data-status');
        setStatus(stylesheetStatus);
      }

      const setStateFromEvent = (event) => {
        const status = event.type === 'load' ? 'ready' : 'error';
        setStatus(status);
      };

      stylesheet.addEventListener('load', setStateFromEvent);
      stylesheet.addEventListener('error', setStateFromEvent);

      return () => {
        if (stylesheet) {
          stylesheet.removeEventListener('load', setStateFromEvent);
          stylesheet.removeEventListener('error', setStateFromEvent);
        }
      };
    }
  }, [src, insertFirst, skip]);

  return status;
}

export default useStylesheet;
