import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultLanguage } from '../../constants';
import useSiteMetadata from '../hooks/useSiteMetadata';

export const PageContext = React.createContext({});

export function usePageContext() {
  const context = React.useContext(PageContext);
  const { url } = useSiteMetadata();
  const { i18n } = context;
  const { originalPath, language: pageLanguage, localized } = i18n;

  const getLocalizedPagePath = (language) => {
    let path;
    if (language === pageLanguage) {
      path = originalPath;
    } else {
      const localizedPage = localized.find(
        (item) => item.language === language
      );
      path = localizedPage.path;
    }

    return language === defaultLanguage ? path : `/${language}${path}`;
  };

  const getPageUrl = () => {
    const path = getLocalizedPagePath(pageLanguage);
    return `${url}${path}`;
  };

  const getLocalizedPageUrl = (language = defaultLanguage) => {
    const path = getLocalizedPagePath(language);
    return `${url}${path}`;
  };

  return {
    ...context,
    getPageUrl,
    getLocalizedPageUrl,
  };
}

function PageContextProvider(props) {
  const { i18n } = useTranslation();
  const { children, pageContext } = props;
  const { language: pageLanguage } = pageContext.i18n;

  useEffect(() => {
    if (pageLanguage !== i18n.language) {
      i18n.changeLanguage(pageLanguage).catch((error) => {
        console.error(error);
      });
    }
  }, [pageLanguage, i18n]);

  return (
    <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
  );
}

export default PageContextProvider;
