import React, { useEffect, useContext, useRef } from 'react';
import { useLocation } from '@reach/router';
import AOS from 'aos';
import Header from './Header';
import Footer from './Footer';
import GlobalContext from '../../context/GlobalContext';
import SpySerpAppFiles from '../SpySerpApp/SpySerpAppFiles';
import SpySerpPanel from '../SpySerpApp/SpySerpPanel';
import Intercom from '../Intercom/Intercom';
import { defaultLanguage, languages } from '../../../constants';

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import 'slick-carousel/slick/slick.css';
import 'aos/dist/aos.css';
import '../../scss/bootstrap.scss';
import '../../scss/main.scss';
import '../SpySerpApp/SpySerpApp.css';
import './Layout.scss';
import usePartnerRefId from '../../hooks/usePartnerRefId';
import { logSmartlookSession } from '../../utils';
import useCookieConsent from '../../hooks/useCookieConsent';

const appPaths = languages.map((lang) =>
  lang === defaultLanguage ? '/app/' : `/${lang}/app/`
);

const Layout = ({ children }) => {
  useCookieConsent();
  usePartnerRefId();
  const { isPanelVisible, visibleOffCanvas, closeOffCanvas } =
    useContext(GlobalContext);
  const { pathname } = useLocation();
  const prevLocationRef = useRef(pathname);

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    if (
      appPaths.includes(prevLocationRef.current) &&
      !appPaths.includes(pathname)
    ) {
      window.location.reload();
    }

    if (prevLocationRef.current !== pathname && isPanelVisible) {
      window.location.reload();
    }

    if (prevLocationRef.current !== pathname && visibleOffCanvas) {
      closeOffCanvas();
    }

    if (!prevLocationRef.current) {
      prevLocationRef.current = pathname;
    } else if (prevLocationRef.current !== pathname) {
      prevLocationRef.current = pathname;
    }
  }, [pathname, isPanelVisible, visibleOffCanvas, closeOffCanvas]);

  if (appPaths.includes(pathname)) {
    return children;
  }

  if (
    appPaths.includes(prevLocationRef.current) &&
    !appPaths.includes(pathname)
  ) {
    return null;
  }

  if (prevLocationRef.current !== pathname && isPanelVisible) {
    return null;
  }

  return (
    <>
      {isPanelVisible && <SpySerpPanel />}

      {!isPanelVisible && <SpySerpAppFiles delay={7000} />}

      {!isPanelVisible && (
        <div className="site-wrapper overflow-hidden">
          <Header isDark={false} />
          {children}
          <Footer isDark={false} />
        </div>
      )}

      <Intercom onConfigLoad={logSmartlookSession} />
    </>
  );
};

export default Layout;
