import React, { useState } from 'react';

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [blogSearchQuery, setBlogSearchQuery] = useState('');
  const [blogSearchActive, setBlogSearchActive] = useState(false);

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        visibleOffCanvas,
        toggleOffCanvas,
        closeOffCanvas,
        blogSearchQuery,
        setBlogSearchQuery,
        blogSearchActive,
        setBlogSearchActive,
        isPanelVisible,
        setIsPanelVisible,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
