import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../i18n/Link';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../context/GlobalContext';
import LongArrowIcon from '../assets/icons/font-awesome/regular/long-arrow-right.svg';
import { defaultLanguage, pages } from '../../constants';
import { usePageContext } from '../i18n/PageContext';

function getUTMCampaignByPath(path) {
  if (path === '/') {
    return 'mainpage';
  }

  const campaignPage = pages.find(
    (page) =>
      page.path === path || Object.values(page.customPaths || {}).includes(path)
  );

  return campaignPage?.utmCampaign || 'other';
}

const GetStartedButton = (props) => {
  const gContext = useContext(GlobalContext);
  const pageContext = usePageContext();
  const { originalPath } = pageContext.i18n;
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const {
    text = t('Try Out For Free'),
    variant = 3,
    hideArrowIcon,
    isLink,
    cardRequired,
    utmCampaign = getUTMCampaignByPath(originalPath),
    utmTerm = 'page_cta',
  } = props;

  const params = new URLSearchParams({
    utm_source: 'Fronpage',
    utm_medium: `lang_${language}`,
    utm_campaign: utmCampaign,
    utm_term: utmTerm,
  });

  const className = classNames('btn gs-btn py-2 px-5', {
    'with-icon': !hideArrowIcon,
    'btn-primary': !isLink,
    'btn-link': isLink,
  });

  // Open SpySERP app in-place without browser url update and page reload
  if (variant === 1) {
    return (
      <button
        className={className}
        onClick={() => {
          gContext.setIsPanelVisible(true);
        }}
      >
        {text}

        {!hideArrowIcon && (
          <span className="fa-svg-icon svg-color-white ml-5">
            <LongArrowIcon />
          </span>
        )}
      </button>
    );
  }

  // Update browser url to /app/ and open SpySERP app without page reload
  if (variant === 4) {
    return (
      <Link className={className} to="/app/">
        {text}

        {!hideArrowIcon && (
          <span className="fa-svg-icon svg-color-white ml-5">
            <LongArrowIcon />
          </span>
        )}
      </Link>
    );
  }

  let href;
  // Redirect browser to SpySERP app
  if (variant === 2) {
    if (language !== defaultLanguage) {
      params.set('clientLang', language);
    }
    href = `/app/?${params.toString()}#start-trial`;
  }

  // Redirect browser to SpySERP signup page
  if (variant === 3) {
    href = `${
      language === 'ru' ? '/ru' : ''
    }/panel/site/signup?${params.toString()}`;
  }

  return (
    <a className={className} href={href}>
      <div className="d-flex flex-column">
        <div>
          {text}

          {!hideArrowIcon && (
            <span className="fa-svg-icon svg-color-white ml-5">
              <LongArrowIcon />
            </span>
          )}
        </div>
        {cardRequired && (
          <div className="text-right gr-text-13 font-weight-normal">
            {t('Card required')}
          </div>
        )}
      </div>
    </a>
  );
};

GetStartedButton.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf([1, 2, 3, 4]),
  hideArrowIcon: PropTypes.bool,
  isLink: PropTypes.bool,
  cardRequired: PropTypes.bool,
  utmCampaign: PropTypes.string,
  utmTerm: PropTypes.oneOf(['top_cta', 'main_cta', 'page_cta']),
};

export default GetStartedButton;
