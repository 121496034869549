import React, { useEffect, useRef } from 'react';
import useSpySerpApp from './useSpySerpApp';
import SpySerpAppFiles from './SpySerpAppFiles';

function SpySerpPanel() {
  const { status } = useSpySerpApp();
  const ref = useRef(null);

  useEffect(() => {
    if (status === 'ready') {
      const { Ext, SpySerpPanel, app, Intercom } = window;
      app.util._splashDestroy();

      if (!Ext.Viewport) Ext.viewport.Viewport.setup({ autoRender: false });

      if (Intercom) {
        Intercom('shutdown');
        Intercom.$$needsReinit = true;
      }

      if (!Ext.getApplication())
        Ext.app.Application.instance = new SpySerpPanel.$application();

      if (app.helpers.socket && !app.helpers.socket.connected) {
        app.helpers.socket.connect();
      }

      Ext.Viewport.render(ref.current);

      Ext.Msg.findFloatParent();

      delete Ext.cache['se-holder'];
      delete Ext.cache['ext-global-floatWrap'];

      Ext.create('SpySerpPanel.view.app.AdminPanel', { fullscreen: true });

      Ext.Viewport.mask({ xtype: 'app-fullmask', msg: 'Connecting&hellip;' });
      Ext.Viewport.show(true);

      Ext.util.History.replace('#projects');
    }

    return () => {
      if (status === 'ready') {
        const { Ext, SpySerpPanel, app, Intercom } = global;

        app.helpers.socket.disconnect();

        Ext.getApplication().destroy();

        if (Ext.floatRoot) {
          Ext.floatRoot.destroy();
          Ext.floatRoot = null;
        }

        SpySerpPanel.controller.AppWide.$sidebars = {};
        SpySerpPanel.controller.AppWide.$$sidebar = null;
        app.vars.mainMenu = null;

        Ext.data.StoreManager.items.forEach((store) => store.clearListeners());

        if (Intercom) Intercom('boot');

        //TODO: найти где устанавливается в фреймворке и убрать там
        document.body.style.overflowY = 'auto';
        document.body.parentElement.classList.remove('x-fixed-viewport');
      }
    };
  }, [status]);

  return (
    <>
      <SpySerpAppFiles preload />
      <div
        ref={ref}
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 99999,
          backgroundColor: '#fff',
        }}
      >
        <div id="splash">
          <div id="splash-content">
            <div className="logo" />
            <div id="status">Loading&hellip;</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpySerpPanel;
