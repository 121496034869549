// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payoneer-js": () => import("./../../../src/pages/payoneer.js" /* webpackChunkName: "component---src-pages-payoneer-js" */),
  "component---src-templates-api-js": () => import("./../../../src/templates/api.js" /* webpackChunkName: "component---src-templates-api-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-backlink-explorer-js": () => import("./../../../src/templates/backlink-explorer.js" /* webpackChunkName: "component---src-templates-backlink-explorer-js" */),
  "component---src-templates-bing-rank-checker-js": () => import("./../../../src/templates/bing-rank-checker.js" /* webpackChunkName: "component---src-templates-bing-rank-checker-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contacts-js": () => import("./../../../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-keyword-grouper-js": () => import("./../../../src/templates/keyword-grouper.js" /* webpackChunkName: "component---src-templates-keyword-grouper-js" */),
  "component---src-templates-keyword-tracker-js": () => import("./../../../src/templates/keyword-tracker.js" /* webpackChunkName: "component---src-templates-keyword-tracker-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-serp-api-js": () => import("./../../../src/templates/serp-api.js" /* webpackChunkName: "component---src-templates-serp-api-js" */),
  "component---src-templates-serp-checker-js": () => import("./../../../src/templates/serp-checker.js" /* webpackChunkName: "component---src-templates-serp-checker-js" */)
}

