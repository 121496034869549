import { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import axios from 'axios';
import useSiteMetadata from './useSiteMetadata';
import { getSessionPromise } from '../utils';

function usePartnerRefId() {
  const { apiUrl } = useSiteMetadata();
  const { href } = useLocation();

  const url = href && new URL(href);
  const params = url && new URLSearchParams(url.search);

  const refId = params?.get('ref_id');
  const refPromocode = params?.get('promocode');

  let to;
  if (refId) {
    params.delete('ref_id');
    params.delete('promocode');
    url.search = params.toString();
    to = `${url.pathname}${url.search}${url.hash}`;
  }

  useEffect(() => {
    if (refId) {
      (async () => {
        try {
          await navigate(to, { replace: true });
          const runId = await getSessionPromise(apiUrl);
          await axios.post(
            apiUrl,
            {
              method: 'addRefId',
              ns: 'front',
              runId,
              refId,
              refPromocode,
            },
            { withCredentials: true }
          );
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [refId, refPromocode, to, apiUrl]);

  return refId;
}

export default usePartnerRefId;
