function stripHtmlTags(str) {
  return str && typeof str === 'string' ? str.replace(/(<([^>]+)>)/gi, '') : '';
}

function getHrefLang(langCode) {
  if (langCode === 'zh_CN') {
    return 'zh-Hans';
  }
  return langCode;
}

module.exports = { stripHtmlTags, getHrefLang };
