/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import useSiteMetadata from '../../hooks/useSiteMetadata';
import useScript from '../../hooks/useScript';

function Intercom(props) {
  const { onConfigLoad } = props;
  const { apiDomain, apiUrl } = useSiteMetadata();
  const [config, setConfig] = useState(null);
  const [isTimeoutDisabled, setIsTimeoutDisabled] = useState(false);
  const timerRef = useRef(null);
  const isLoadingConfigRef = useRef(false);
  const timeout = 15000;

  const loadIntercom = () => {
    isLoadingConfigRef.current = true;
    const configURL = ['localhost', 'front-staging.spyserp.com'].includes(
      window.location.hostname
    )
      ? `https://${apiDomain}/panel/site/init-intercom-config`
      : '/panel/site/init-intercom-config';

    axios
      .get(configURL)
      .then((response) => {
        if (response?.data?.app_id) {
          setConfig({
            ...response?.data,
            hide_default_launcher: false,
          });

          if (typeof onConfigLoad === 'function') {
            onConfigLoad({ ...response.data, apiDomain, apiUrl });
          }
        } else {
          Sentry.captureMessage('Got invalid intercom config');
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    if (typeof window.Intercom === 'function') {
      window.Intercom('reattach_activator');
    } else {
      const i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
        if (args[0] === 'show') {
          setIsTimeoutDisabled(true);
        }
      };

      window.Intercom = i;
    }
  }, []);

  const intercomStatus = useScript(
    `https://widget.intercom.io/widget/${config?.app_id}`,
    {
      asyncLoad: true,
      skip: !config,
    }
  );

  useEffect(() => {
    if (intercomStatus === 'skipped') {
      timerRef.current = setTimeout(loadIntercom, timeout);
      return () => clearTimeout(timerRef.current);
    }
  }, [apiDomain, intercomStatus, timeout]);

  useEffect(() => {
    if (intercomStatus === 'ready') {
      window.Intercom('boot', config);
    }
  }, [intercomStatus, config]);

  useEffect(() => {
    if (
      isTimeoutDisabled &&
      !isLoadingConfigRef.current &&
      timerRef.current &&
      !config
    ) {
      clearTimeout(timerRef.current);
      loadIntercom();
    }
  }, [isTimeoutDisabled, config]);

  return null;
}

export default Intercom;
