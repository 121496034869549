const defaultLanguage = 'en';

const languages = [
  defaultLanguage,
  'ru',
  'es',
  'de',
  'fr',
  'it',
  'nl',
  'tr',
  'pt',
  'zh_CN',
  'ja',
  'pl',
  'sv',
  'vi',
];

const languageOptions = {
  en: 'English',
  ru: 'Русский',
  es: 'Español',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  nl: 'Nederlands',
  tr: 'Türkçe',
  pt: 'Português',
  zh_CN: '简化字',
  ja: '日本語',
  pl: 'Polski',
  sv: 'Svenska',
  vi: 'tiếng Việt',
};

const i18nNamespaces = [
  'translation',
  'Layout',
  'RankTracker',
  'Pricing',
  'SerpChecker',
  'KeywordGrouper',
  'KeywordTracker',
  'BingRankChecker',
  'Contacts',
  'Blog',
  'SerpApi',
  'BackLinkExplorer',
  'CookieConsent',
];

const pages = [
  {
    path: '/serp-checker/',
    file: 'serp-checker.js',
    utmCampaign: 'serpchecker',
  },
  {
    path: '/keyword-tracker/',
    file: 'keyword-tracker.js',
    utmCampaign: 'keywordtracker',
  },
  {
    path: '/keyword-grouping/',
    file: 'keyword-grouper.js',
    customPaths: { ru: '/clusterization/' },
    utmCampaign: 'keywordgrouper',
  },
  {
    path: '/bing-rank-checker/',
    file: 'bing-rank-checker.js',
    utmCampaign: 'bingchecker',
  },
  {
    path: '/contacts/',
    file: 'contacts.js',
  },
  {
    path: '/api/',
    file: 'api.js',
    languages: ['en', 'ru'],
  },
  {
    path: '/serp-api/',
    file: 'serp-api.js',
  },
  {
    path: '/backlink-explorer/',
    file: 'backlink-explorer.js',
  },
];

const blogLanguages = ['en', 'ru'];
const enBlogId = 2;
const ruBlogId = 1;
const blogs = [enBlogId, ruBlogId];
const blogIdToLanguageMapping = {
  [enBlogId]: 'en',
  [ruBlogId]: 'ru',
};

module.exports = {
  defaultLanguage,
  languages,
  i18nNamespaces,
  languageOptions,
  pages,
  blogLanguages,
  enBlogId,
  ruBlogId,
  blogs,
  blogIdToLanguageMapping,
};
