import React from 'react';
import { Container } from 'react-bootstrap';
import Logo from '../Logo';
import classNames from 'classnames';

const Offcanvas = ({ show, onHideOffcanvas, children, ...rest }) => {
  return (
    <div {...rest}>
      <div
        className={classNames('overlay', { hidden: !show })}
        onClick={onHideOffcanvas}
      />
      <div className={classNames('drawer', { hidden: !show })}>
        <Container>
          <div className="p-3">
            <div className="my-3">
              <Logo />
            </div>
            <div className="pt-4">{children}</div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Offcanvas;
