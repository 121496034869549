import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Logo from '../Logo';
import Link from '../../i18n/Link';
import FacebookIcon from '../../assets/icons/font-awesome/brands/facebook-f.svg';
import LinkedinIcon from '../../assets/icons/font-awesome/brands/linkedin.svg';

const Footer = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const { t, i18n } = useTranslation('Layout');
  const { language } = i18n;

  return (
    <div
      className="footer-section pt-15 pt-lg-25"
      itemScope={true}
      itemType="https://schema.org/WPFooter"
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg="3" md="4" className="mb-15">
            <Logo />
            <div className="footer-title font-weight-bold text-blackish-blue mt-8">
              {t('footer.title')}
            </div>
          </Col>

          <Col lg="8" md="8" className="offset-lg-1">
            <Row>
              <Col xs="12" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="gr-text-11 mb-7">{t('footer.tools.title')}</p>

                  <ul className="footer-list list-unstyled gr-text-9">
                    <li className="py-2">
                      <Link to="/" className="gr-text-color gr-hover-text-blue">
                        {t('footer.tools.rankTracker')}
                      </Link>
                    </li>

                    <li className="py-2">
                      <Link
                        to="/serp-checker/"
                        className="gr-text-color gr-hover-text-blue"
                      >
                        {t('footer.tools.serpChecker')}
                      </Link>
                    </li>

                    <li className="py-2">
                      <Link
                        to="/keyword-tracker/"
                        className="gr-text-color gr-hover-text-blue"
                      >
                        {t('footer.tools.keywordTracker')}
                      </Link>
                    </li>

                    <li className="py-2">
                      <Link
                        to="/bing-rank-checker/"
                        className="gr-text-color gr-hover-text-blue"
                      >
                        {t('footer.tools.bingRankChecker')}
                      </Link>
                    </li>

                    <li className="py-2">
                      <Link
                        to="/serp-api/"
                        className="gr-text-color gr-hover-text-blue"
                      >
                        SERP Data API
                      </Link>
                    </li>

                    <li className="py-2">
                      <Link
                        to="/backlink-explorer/"
                        className="gr-text-color gr-hover-text-blue"
                      >
                        BackLink Explorer
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col xs="12" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="gr-text-11 mb-7">SpySERP</p>

                  <ul className="footer-list list-unstyled gr-text-9">
                    <li className="py-2">
                      <Link
                        to="/#features"
                        className="gr-text-color gr-hover-text-blue"
                      >
                        {t('footer.spyserp.features')}
                      </Link>
                    </li>

                    <li className="py-2">
                      <Link
                        to="/#pricing"
                        className="gr-text-color gr-hover-text-blue"
                      >
                        {t('footer.spyserp.pricing')}
                      </Link>
                    </li>

                    {['en', 'ru'].includes(language) && (
                      <li className="py-2">
                        <Link
                          to="/blog/"
                          className="gr-text-color gr-hover-text-blue"
                        >
                          {t('footer.spyserp.blog')}
                        </Link>
                      </li>
                    )}

                    {['en', 'ru'].includes(language) && (
                      <li className="py-2">
                        <Link
                          to="/terms-of-referral-program/"
                          className="gr-text-color gr-hover-text-blue"
                        >
                          {t('footer.spyserp.referralProgram')}
                        </Link>
                      </li>
                    )}

                    {!['en', 'ru'].includes(language) && (
                      <li className="py-2">
                        <Link
                          language="en"
                          to="/terms-of-referral-program/"
                          className="gr-text-color gr-hover-text-blue"
                        >
                          {t('footer.spyserp.referralProgram')}
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </Col>

              <Col xs="12" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="gr-text-11 mb-7">{t('footer.help.title')}</p>

                  <ul className="footer-list list-unstyled gr-text-9">
                    <li className="py-2">
                      <Link
                        language={language === 'ru' ? 'ru' : 'en'}
                        className="gr-text-color gr-hover-text-blue"
                        to="/api/"
                        isExternal={!['ru', 'en'].includes(language)}
                      >
                        {t('footer.help.api')}
                      </Link>
                    </li>

                    <li className="py-2">
                      <Link
                        to="/contacts/"
                        className="gr-text-color gr-hover-text-blue"
                      >
                        {t('footer.help.contacts')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col xs="12" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="gr-text-11 mb-7">{t('footer.support.title')}</p>
                  <p className="gr-text-10">{t('footer.support.subtitle')}</p>

                  <ul className="list-inline social-icons">
                    <li
                      data-toggle="tooltip"
                      className="fb-icon"
                      title="Facebook"
                    >
                      <a
                        href="https://www.facebook.com/spyserp.global/"
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                        title="Facebook"
                      >
                        <span className="fa-svg-icon svg-color-primary">
                          <FacebookIcon />
                        </span>
                      </a>
                    </li>

                    <li
                      data-toggle="tooltip"
                      className="telegram-icon"
                      title="LinkedIn"
                    >
                      <a
                        href="https://linkedin.com/company/spyserp"
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                        title="LinkedIn"
                      >
                        <span className="fa-svg-icon svg-color-primary">
                          <LinkedinIcon />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div className="mt-10 bg-dark text-white p-4">
        <Container className="d-flex flex-sm-row-reverse justify-content-between flex-wrap">
          <div className="flex-grow-1 flex-sm-grow-0">
            <Link language="en" to="/terms-of-service/" className="text-white">
              {t('footer.terms')}
            </Link>
            <span className="mx-5">|</span>
            <Link language="en" to="/privacy-policy/" className="text-white">
              {t('footer.privacyPolicy')}
            </Link>
          </div>

          <div>
            ©
            <span itemProp="copyrightHolder" className="mx-2">
              SpySERP
            </span>
            <span itemProp="copyrightYear">2016</span> - {currentYear}.
            <span className="ml-3">{t('footer.rights')}</span>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
