import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import GlobalContext from '../../context/GlobalContext';
import Offcanvas from '../Offcanvas';
import Logo from '../Logo';
import Link from '../../i18n/Link';
import LanguagePicker from './LanguagePicker';
import BarsIcon from '../../assets/icons/font-awesome/solid/bars.svg';
import GetStartedButton from '../GetStartedButton';
import LanguagePickerMobile from './LanguagePickerMobile';

const Header = () => {
  const { t, i18n } = useTranslation('Layout');
  const { visibleOffCanvas, toggleOffCanvas } = useContext(GlobalContext);
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);
  const { language } = i18n;
  const menuItems = [
    { name: 'home', label: t('header.links.rankTracker'), path: '/' },
    { name: 'pricing', label: t('header.links.pricing'), path: '/#pricing' },
  ];

  menuItems.splice(1, 0, {
    name: 'serp-checker',
    label: t('header.links.serpChecker'),
    path: '/serp-checker/',
  });

  menuItems.splice(2, 0, {
    name: 'keyword-grouper',
    label: t('header.links.keywordGrouper'),
    path: language !== 'ru' ? '/keyword-grouping/' : '/clusterization/',
  });

  menuItems.splice(3, 0, {
    name: 'backlink-explorer',
    label: t('header.links.backlinkExplorer'),
    path: '/backlink-explorer/',
  });

  if (['en', 'ru'].includes(language)) {
    menuItems.push({
      name: 'blog',
      label: t('header.links.blog'),
      path: '/blog/',
    });
  }

  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
  });

  return (
    <>
      <div
        className={classNames(
          'site-header site-header--absolute site-header--sticky py-0',
          {
            scrolling: showScrolling,
            'reveal-header': showReveal,
          }
        )}
      >
        <Container className="pr-lg-9 pl-lg-9" fluid>
          <nav className="navbar site-navbar offcanvas-active navbar-expand-lg px-0">
            <div className="brand-logo">
              <Logo />
            </div>

            <div className="collapse navbar-collapse">
              <div className="navbar-nav-wrapper main-menu-wrapper flex-nowrap d-flex">
                <ul className="navbar-nav main-menu mr-auto d-none d-lg-flex pl-10">
                  {menuItems.map((item, index) => {
                    const {
                      label,
                      isExternal = false,
                      name,
                      path,
                      items,
                      ...rest
                    } = item;

                    return (
                      <li key={name + index} className="nav-item" {...rest}>
                        {isExternal ? (
                          <a
                            className="nav-link"
                            href={path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {label}
                          </a>
                        ) : (
                          <Link
                            to={path}
                            getProps={(props) => {
                              const { isCurrent, location } = props;
                              return (isCurrent &&
                                location.hash !== '#pricing') ||
                                (location.hash === '#pricing' &&
                                  name === 'pricing')
                                ? { className: 'nav-link active-link' }
                                : { className: 'nav-link' };
                            }}
                            role="button"
                          >
                            {label}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>

                <LanguagePicker />

                <ul className="navbar-nav main-menu d-lg-flex mr-2">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={
                        language === 'ru' ? '/ru/panel/signin' : '/panel/signin'
                      }
                    >
                      {t('header.links.signin')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="header-btn ml-lg-0 mr-6 mr-lg-0 d-none d-xs-block">
              <GetStartedButton
                text={t('header.links.cta')}
                utmTerm="top_cta"
                hideArrowIcon
                cardRequired
              />
            </div>

            <button
              className={classNames(
                'navbar-toggler btn-close-off-canvas ml-3',
                { collapsed: visibleOffCanvas }
              )}
              type="button"
              data-toggle="collapse"
              data-target="#mobile-menu"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={toggleOffCanvas}
            >
              <span className="fa-svg-icon">
                <BarsIcon />
              </span>
            </button>
          </nav>
        </Container>
      </div>

      <Offcanvas show={visibleOffCanvas} onHideOffcanvas={toggleOffCanvas}>
        <div className="list-group list-group-flush list-group-mobile">
          <div className="list-group-item">
            <Link to="/">{t('header.links.rankTracker')}</Link>
          </div>

          <div className="list-group-item">
            <Link to="/serp-checker/">{t('header.links.serpChecker')}</Link>
          </div>

          <div className="list-group-item">
            <Link to="/keyword-tracker/">
              {t('header.links.keywordTracker')}
            </Link>
          </div>

          <div className="list-group-item">
            <Link to="/backlink-explorer/">
              {t('header.links.backlinkExplorer')}
            </Link>
          </div>

          {['en', 'ru'].includes(language) && (
            <div className="list-group-item">
              <Link to="/blog/">{t('header.links.blog')}</Link>
            </div>
          )}

          <div className="list-group-item">
            <a href={language === 'ru' ? '/ru/panel/signin' : '/panel/signin'}>
              {t('header.links.signin')}
            </a>
          </div>

          <LanguagePickerMobile />
        </div>
      </Offcanvas>
    </>
  );
};
export default Header;
