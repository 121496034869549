export function getSessionPromise(apiUrl) {
  if (!window.sessionPromise) {
    window.sessionPromise = new Promise((resolve, reject) => {
      const branch = 'master';
      const xhttp = new XMLHttpRequest();
      xhttp.withCredentials = true;

      xhttp.onreadystatechange = function () {
        if (this.readyState === 4) {
          let success = false;
          let response;

          if (this.status === 200) {
            try {
              response = JSON.parse(this.responseText);
            } catch (e) {
              response = { success: false, error: e.toString() };
            }

            if (response.success) {
              success = true;
              resolve(response.runId);
            }
          }

          if (!success) {
            const error = new Error('start-session-failed');
            error.$$details = { status: this.status };
            if (response) error.$$details.response = response;
            reject(error);
          }
        }
      };

      xhttp.open('POST', `${apiUrl}startSession`, true);

      const payload = { ns: 'panel', method: 'startSession', branch };
      if (window.apiToken) {
        payload.token = window.apiToken;
      }
      xhttp.send(JSON.stringify(payload));
    });
  }

  return window.sessionPromise;
}
