import Link from '../../i18n/Link';
import React from 'react';
import { useLocation } from '@reach/router';
import { usePageContext } from '../../i18n/PageContext';
import { blogLanguages, languageOptions } from '../../../constants';

function LanguagePicker() {
  const { i18n } = usePageContext();
  const { language, originalPath, localized } = i18n;
  const { hash } = useLocation();

  const localizedPaths = localized.reduce((acc, item) => {
    acc[item.language] = item.path;
    return acc;
  }, {});

  return (
    <>
      {Object.keys(languageOptions).map((lang) => {
        if (lang === language) {
          return (
            <div key={lang} className="list-group-item">
              <Link
                className="active-link"
                language={language}
                to={`${originalPath}${hash}`}
                style={{ display: 'list-item' }}
                isExternal
              >
                {languageOptions[lang]}
              </Link>
            </div>
          );
        } else if (localizedPaths[lang]) {
          return (
            <div key={lang} className="list-group-item">
              <Link
                language={lang}
                to={`${localizedPaths[lang]}${hash}`}
                style={{ display: 'list-item' }}
                isExternal
              >
                {languageOptions[lang]}
              </Link>
            </div>
          );
        } else if (
          originalPath.startsWith('/author/') &&
          blogLanguages.includes(lang)
        ) {
          return (
            <div key={lang} className="list-group-item">
              <Link
                language={lang}
                to="/blog/"
                style={{ display: 'list-item' }}
                isExternal
              >
                {languageOptions[lang]}
              </Link>
            </div>
          );
        } else {
          return (
            <div key={lang} className="list-group-item">
              <Link
                language={lang}
                to="/"
                style={{ display: 'list-item' }}
                isExternal
              >
                {languageOptions[lang]}
              </Link>
            </div>
          );
        }
      })}
    </>
  );
}

export default LanguagePicker;
