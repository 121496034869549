import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { defaultLanguage } from '../../constants';
import { usePageContext } from './PageContext';
import { getHrefLang } from '../../utils';

export function getLocalizedPath(language, link) {
  const languagePath = language !== defaultLanguage ? `/${language}` : '';
  const path = link === '/' && language !== defaultLanguage ? '/' : link;
  return `${languagePath}${path}`;
}

function Link(props) {
  const { i18n } = usePageContext();
  const { language: originalLanguage, originalPath } = i18n;
  const {
    language = originalLanguage,
    to = originalPath,
    onClick,
    isExternal,
    ...rest
  } = props;

  const originalLanguagePath =
    originalLanguage !== defaultLanguage ? `/${originalLanguage}` : '';
  const pagePath =
    originalPath === '/' && originalLanguage !== defaultLanguage
      ? '/'
      : originalPath;

  const link = getLocalizedPath(language, to);
  const isExternalLink = isExternal || language !== originalLanguage;

  if (isExternalLink) {
    return (
      <a {...rest} href={link} hrefLang={getHrefLang(language)}>
        {rest.children}
      </a>
    );
  }

  return (
    <GatsbyLink
      {...rest}
      to={link}
      hrefLang={getHrefLang(language)}
      aria-current={
        `${originalLanguagePath}${pagePath}` === link ? 'page' : undefined
      }
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
    />
  );
}

export default Link;
