module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://82d3acf002f243ac9af9cea20de4f81f@o79517.ingest.sentry.io/1333364","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SpySERP","short_name":"SpySERP","start_url":"/","background_color":"#fcfdfe","theme_color":"#fcfdfe","display":"standalone","include_favicon":true,"legacy":true,"icon":"src/assets/favicons/favicon.svg","icons":[{"src":"/favicons/favicon-192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/favicon-512.png","sizes":"512x512","type":"image/png"}],"crossOrigin":"use-credentials","theme_color_in_head":true,"cache_busting_mode":"query","cacheDigest":"31ab860dfc9ea6e7cc7785306aa47ae1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
