import axios from 'axios';
import { getSessionPromise } from './session';

export async function logSmartlookSession(config) {
  const { apiDomain, apiUrl } = config;
  const initTimeoutError = 'Smartlook init timeout';

  if (apiDomain === 'spyserp.com' && config.email) {
    new Promise((resolve, reject) => {
      if (window.smartlook) {
        window.smartlook(resolve);
        setTimeout(() => reject(initTimeoutError), 15 * 1000);
      } else {
        reject('Smartlook not loaded');
      }
    })
      .then(async () => {
        const runId = await getSessionPromise(apiUrl);

        return axios.post(`${apiUrl}logSmartlookSession`, {
          method: 'logSmartlookSession',
          playUrl: window.smartlook.playUrl,
          isRestart: false,
          runId,
          ns: 'panel',
        });
      })
      .catch((error) => {
        if (error === initTimeoutError) {
          window.localStorage &&
            localStorage.removeItem('smartlook_ban_expire');

          window.smartlook('init', '81cb7e7ba0d38506176639bd3edf99eea66c535b');
          window.smartlook._restart && window.smartlook._restart();

          if (window.smartlookRestarts) {
            window.smartlookRestarts = 1;
          } else {
            window.smartlookRestarts += 1;
          }

          logSmartlookSession(config);

          if (window.smartlookRestarts !== 2) return;
        }

        getSessionPromise(apiUrl)
          .then((runId) => {
            return axios.post(`${apiUrl}logSmartlookSessionError`, {
              error: error.message || error,
              runId,
              ns: 'panel',
            });
          })
          .catch(() => {
            // failed to send error
          });
      });
  }
}
