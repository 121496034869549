import { useEffect, useState } from 'react';

function useScript(src, options = {}) {
  const { asyncLoad = false, skip = false } = options;
  // Keep track of script status ("loading", "ready", "error", "skipped")
  const [status, setStatus] = useState(skip ? 'skipped' : 'loading');

  useEffect(() => {
    if (!skip) {
      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        script = document.createElement('script');
        script.src = src;
        script.async = asyncLoad;
        script.setAttribute('data-status', 'loading');
        document.body.appendChild(script);

        const setAttributeFromEvent = (event) => {
          const status = event.type === 'load' ? 'ready' : 'error';
          script.setAttribute('data-status', status);
        };

        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        const scriptStatus = script.getAttribute('data-status');
        setStatus(scriptStatus);
      }

      const setStateFromEvent = (event) => {
        const status = event.type === 'load' ? 'ready' : 'error';
        setStatus(status);
      };

      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    }
  }, [src, asyncLoad, skip]);

  return status;
}

export default useScript;
