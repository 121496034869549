import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { defaultLanguage, i18nNamespaces } from '../../constants';

let init;

const isSSR = typeof window === 'undefined';

export function initI18n(options) {
  const { language = defaultLanguage, resources = {} } = options;
  if (!init) {
    init = i18n
      .use(initReactI18next)
      .init({
        lng: language,
        resources,
        debug: process.env.NODE_ENV === 'development',
        nsSeparator: ':',
        keySeparator: '.',
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
        defaultNS: 'translation',
      })
      .catch((error) => {
        console.error(error);
      });
  }

  if (isSSR && i18n.language !== language) {
    i18n.changeLanguage(language);
  }
  return init;
}

export function getI18nStoreData() {
  const initialI18nStore = {};
  initialI18nStore[i18n.language] = {};

  i18nNamespaces.forEach((namespace) => {
    if (i18n.services.resourceStore.data[i18n.language]) {
      initialI18nStore[i18n.language][namespace] =
        i18n.services.resourceStore.data[i18n.language][namespace];
    }
  });

  return initialI18nStore;
}

export function getI18nLanguage() {
  return i18n.language;
}

export default i18n;
