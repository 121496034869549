import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import useSiteMetadata from '../../hooks/useSiteMetadata';

function SpySerpAppFiles(props) {
  const { preload, delay = 5000 } = props;
  const [isDocumentReady, setIsDocumentReady] = useState(false);

  useEffect(() => {
    const documentReadyHandler = () => {
      setTimeout(() => {
        setIsDocumentReady(true);
      }, delay);
    };

    if (document.readyState === 'complete') {
      documentReadyHandler();
    } else {
      window.addEventListener('load', documentReadyHandler);
      return () => window.removeEventListener('load', documentReadyHandler);
    }
  }, [delay]);

  const { spySerpApp, appBaseUrl, isLocalApp } = useSiteMetadata();
  const {
    panelAllCssFile,
    fontAwesomeCssFile,
    commonCssFile,
    frameworkJsFile,
    objectHashJsFile,
    socketIoJsFile,
    appJsFile,
  } = spySerpApp;

  if (isLocalApp) {
    return null;
  }

  if (delay && !isDocumentReady) {
    return null;
  }

  return (
    <Helmet>
      <link
        rel={preload ? 'preload' : 'prefetch'}
        href={`${appBaseUrl}/${panelAllCssFile}`}
        as="style"
      />

      <link
        rel={preload ? 'preload' : 'prefetch'}
        href={`${appBaseUrl}/${fontAwesomeCssFile}`}
        as="style"
      />

      <link
        rel={preload ? 'preload' : 'prefetch'}
        href={`${appBaseUrl}/${commonCssFile}`}
        as="style"
      />

      <link
        rel={preload ? 'preload' : 'prefetch'}
        href={`${appBaseUrl}/${frameworkJsFile}`}
        as="script"
      />

      <link
        rel={preload ? 'preload' : 'prefetch'}
        href={`${appBaseUrl}/${objectHashJsFile}`}
        as="script"
      />

      <link
        rel={preload ? 'preload' : 'prefetch'}
        href={`${appBaseUrl}/${socketIoJsFile}`}
        as="script"
      />

      <link
        rel={preload ? 'preload' : 'prefetch'}
        href={`${appBaseUrl}/${appJsFile}`}
        as="script"
      />
    </Helmet>
  );
}

export default SpySerpAppFiles;
