import { useStaticQuery, graphql } from 'gatsby';

function useSiteMetadata() {
  const { site, spySerpApp } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            apiDomain
            apiSocketUrl
            apiUrl
            appBaseUrl
            isLocalApp
            url
            organization {
              name
              address {
                street
                city
                postcode
                country
              }
              email
              telegramChatLink
              facebookLink
              linkedinLink
            }
          }
        }

        spySerpApp {
          panelAllCssFile
          fontAwesomeCssFile
          commonCssFile
          frameworkJsFile
          objectHashJsFile
          socketIoJsFile
          appJsFile
        }
      }
    `
  );

  return {
    ...site.siteMetadata,
    spySerpApp,
  };
}

export default useSiteMetadata;
