import React from 'react';
import Link from '../i18n/Link';
import { StaticImage } from 'gatsby-plugin-image';

const Logo = () => {
  return (
    <Link to="/" className="d-block">
      {
        <StaticImage
          placeholder="none"
          width={150}
          quality={100}
          loading="eager"
          src="../assets/image/spyserp/logo.png"
          alt="SpySERP.com"
        />
      }
    </Link>
  );
};

export default Logo;
