import * as CookieConsent from 'vanilla-cookieconsent';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const config = {
  guiOptions: {
    consentModal: {
      layout: 'bar inline',
      // position: 'bottom left',
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'left',
      flipButtons: false,
    },
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      enabled: true,
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
  },

  language: {},
};

function useCookieConsent() {
  const { i18n, t } = useTranslation('CookieConsent');

  config.language.default = i18n.language;
  config.language.translations = {};
  config.language.translations[i18n.language] = {
    consentModal: {
      title: t('consentModal.title'),
      description: t('consentModal.description'),
      acceptAllBtn: t('consentModal.acceptAllBtn'),
      showPreferencesBtn: t('consentModal.showPreferencesBtn'),
      footer: `
                  <a href="/privacy-policy/">${t(
                    'consentModal.privacyPolicyTitle'
                  )}</a>  <a href="/terms-of-service/">${t(
        'consentModal.termsOfServiceTitle'
      )}</a>
              `,
    },
    preferencesModal: {
      title: t('preferencesModal.title'),
      savePreferencesBtn: t('preferencesModal.savePreferencesBtn'),
      closeIconLabel: t('preferencesModal.closeIconLabel'),
      sections: [
        {
          title: t('preferencesModal.sectionOne.title'),
          description: `${t(
            'preferencesModal.sectionOne.descriptionText'
          )} <a href="/privacy-policy/" class="cc__link">${t(
            'preferencesModal.sectionOne.descriptionLinkTitle'
          )}</a>`,
        },
        {
          title: t('preferencesModal.sectionTwo.title'),
          description: t('preferencesModal.sectionTwo.description'),
          linkedCategory: 'necessary',
        },
        {
          title: t('preferencesModal.sectionTree.title'),
          description: t('preferencesModal.sectionTree.description'),
          linkedCategory: 'analytics',
        },
        {
          title: t('preferencesModal.sectionFour.title'),
          description: `${t(
            'preferencesModal.sectionFour.descriptionText'
          )} <a class="cc__link" href="/contacts/">${t(
            'preferencesModal.sectionFour.descriptionLinkTitle'
          )}</a>.`,
        },
      ],
    },
  };

  useEffect(() => {
    CookieConsent.run(config);
  }, []);

  return CookieConsent;
}

export default useCookieConsent;
