import Link from '../../i18n/Link';
import React from 'react';
import { useLocation } from '@reach/router';
import { usePageContext } from '../../i18n/PageContext';
import GlobeIcon from '../../assets/icons/font-awesome/solid/globe-americas.svg';
import { blogLanguages, languageOptions } from '../../../constants';

function LanguagePicker() {
  const { i18n } = usePageContext();
  const { language, originalPath, localized } = i18n;
  const { hash } = useLocation();

  const localizedPaths = localized.reduce((acc, item) => {
    acc[item.language] = item.path;
    return acc;
  }, {});

  return (
    <div className="navbar-nav main-menu">
      <div
        className="nav-item dropdown nav-link dropdown-toggle gr-toggle-arrow"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span>
          <GlobeIcon width={24} />
        </span>

        <ul className="gr-menu-dropdown dropdown-menu">
          {Object.keys(languageOptions).map((lang) => {
            if (lang === language) {
              return (
                <li key={lang} className="drop-menu-item">
                  <Link
                    className="active-link"
                    language={language}
                    to={`${originalPath}${hash}`}
                    style={{ display: 'list-item' }}
                    isExternal
                  >
                    {languageOptions[lang]}
                  </Link>
                </li>
              );
            } else if (localizedPaths[lang]) {
              return (
                <li key={lang} className="drop-menu-item">
                  <Link
                    language={lang}
                    to={`${localizedPaths[lang]}${hash}`}
                    style={{ display: 'list-item' }}
                    isExternal
                  >
                    {languageOptions[lang]}
                  </Link>
                </li>
              );
            } else if (
              originalPath.startsWith('/author/') &&
              blogLanguages.includes(lang)
            ) {
              return (
                <li key={lang} className="drop-menu-item">
                  <Link
                    language={lang}
                    to="/blog/"
                    style={{ display: 'list-item' }}
                    isExternal
                  >
                    {languageOptions[lang]}
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={lang} className="drop-menu-item">
                  <Link
                    language={lang}
                    to="/"
                    style={{ display: 'list-item' }}
                    isExternal
                  >
                    {languageOptions[lang]}
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
}

export default LanguagePicker;
