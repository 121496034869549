import useSiteMetadata from '../../hooks/useSiteMetadata';
import useScript from '../../hooks/useScript';
import { usePageContext } from '../../i18n/PageContext';
import { useState } from 'react';
import useStylesheet from '../../hooks/useStylesheet';
import { getSessionPromise } from '../../utils';

function getQueryParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function useSpySerpApp() {
  const isSpySERPAppReady =
    typeof window !== 'undefined' && window.isSpySERPAppReady;
  const [status, setStatus] = useState(isSpySERPAppReady ? 'ready' : 'loading');
  const { i18n } = usePageContext();
  const { language } = i18n;
  const { spySerpApp, appBaseUrl, isLocalApp, apiSocketUrl, apiUrl } =
    useSiteMetadata();

  const {
    panelAllCssFile,
    fontAwesomeCssFile,
    commonCssFile,
    frameworkJsFile,
    objectHashJsFile,
    socketIoJsFile,
    appJsFile,
  } = spySerpApp;

  const appReadyCallback = () => {
    const { app } = window;
    app.configOverrides = {
      ...app.configOverrides,
      apiSocketUrl,
      apiAjaxUrl: apiUrl,
    };
    app.init();
    window.isSpySERPAppReady = true;
    setStatus('ready');
  };

  if (typeof window !== 'undefined' && !window.isSpySERPConfigSet) {
    window.SpySERPAppLanguage = language;
    window.SpySERPAppRunID = 0;
    window.SpySERPAppManual = 1;
    window.SpySERPUserFlags = {};
    window.SpySERPFrontVersion = 2;
    window.gatsbyNotifyAppReady = appReadyCallback;
    window.getQueryParameterByName = getQueryParameterByName;
    window.SpySERPAppCurrentRoot = 'app';
    window.SpySERPAppCurrentBranch = '';
    getSessionPromise(apiUrl);

    //TODO: rewrite/remove
    window.SpySERPAppInterceptConsole = false;
    console.native = {};
    for (const fn of ['log', 'info', 'warn', 'debug', 'error'])
      console.native[fn] = console[fn];

    window.isSpySERPConfigSet = true;
    window.onRecaptchaLoad = function () {
      window.SpySERPRecaptchaLoaded = true;
      window.SpySERPRecaptchaLoadedHandler?.();
    };
  }

  useStylesheet('https://fonts.googleapis.com/icon?family=Material+Icons', {
    insertFirst: true,
  });

  useScript(`${appBaseUrl}/resources/emoji/dist/browser.js`);

  useStylesheet(`${appBaseUrl}/${commonCssFile}`, {
    insertFirst: true,
    skip: isLocalApp,
  });

  useStylesheet(`${appBaseUrl}/${fontAwesomeCssFile}`, {
    insertFirst: true,
    skip: isLocalApp,
  });

  useStylesheet(`${appBaseUrl}/${panelAllCssFile}`, {
    insertFirst: true,
    skip: isLocalApp,
  });

  useScript(`${appBaseUrl}/${frameworkJsFile}`, {
    skip: isLocalApp,
  });

  useScript(`${appBaseUrl}/${objectHashJsFile}`, {
    skip: isLocalApp,
  });

  useScript(`${appBaseUrl}/${socketIoJsFile}`, {
    skip: isLocalApp,
  });

  useScript(`${appBaseUrl}/${appJsFile}`, {
    skip: isLocalApp,
  });

  useScript('/bootstrap.js', {
    skip: !isLocalApp,
  });

  return { status };
}

export default useSpySerpApp;
