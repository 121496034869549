import React from 'react';
import Layout from '../components/Layout';
import PageContextProvider from './PageContext';
import { initI18n } from './index';

function PageWrapper({ element, props }) {
  const { language } = props.pageContext.i18n;
  initI18n({ language, resources: window.initialI18nStore });

  const isCleanLayout =
    props.pageContext?.i18n?.originalPath?.endsWith('-clean-layout/');

  return (
    <PageContextProvider pageContext={props.pageContext}>
      {isCleanLayout && <div>{element}</div>}
      {!isCleanLayout && <Layout {...props}>{element}</Layout>}
    </PageContextProvider>
  );
}

export default PageWrapper;
